import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import DesignRush from '../../../assets/logos/DesignRush.png';
import LMTP from '../../../assets/logos/lmtp.svg';
import PIKD from '../../../assets/logos/pikd-white.png';
import RLAA from '../../../assets/logos/rlaa.svg';
import { MOBILE_WIDTH } from '../../../hooks/useWindowDimensions';
import Client, { ClientType } from './Client';

function ClientsList() {
    const [t] = useTranslation('featuredClients');

    const clients: ClientType[] = [
        {
            businessName: t('clients.PIKD.businessName'),
            description: t('clients.PIKD.description'),
            logo: <Logo src={PIKD} />,
        },
        {
            businessName: t('clients.RLAA.businessName'),
            description: t('clients.RLAA.description'),
            logo: <Logo src={RLAA} />,
        },
        {
            businessName: t('clients.LTPM.businessName'),
            description: t('clients.LTPM.description'),
            logo: <LMTPLogo src={LMTP} />,
        },
        {
            businessName: t('clients.DesignRush.businessName'),
            description: t('clients.DesignRush.description'),
            logo: (
                <DesignRushLogo
                    src={DesignRush}
                    alt={t('clients.DesignRush.alt')}
                    onClick={() =>
                        window.open(
                            'https://www.designrush.com/agency/web-development-companies/ca',
                            '_blank'
                        )
                    }
                />
            ),
        },
    ];

    return (
        <Container>
            {clients.map((client) => (
                <Client
                    key={client.businessName}
                    businessName={client.businessName}
                    logo={client.logo}
                    description={client.description}
                />
            ))}
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: column;
        margin: 50px 0 0 0;
    }
`;

const Logo = styled.img`
    width: 100px;
`;

const LMTPLogo = styled.img`
    width: 50px;
`;

const DesignRushLogo = styled.img`
    width: 70px;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;

export default ClientsList;
